import api from "../api/client";
import wiretransferapi from "../api/wiretransfer";

const options = {
    endpoint: "api/v1/MediaVendors",
    baseEndpoint: "api/v1/MediaVendors",
    data: {},
};
export default {
    AddRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/`;
        return api.post(options);
    },
    AddRequestWithFiles(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/`;
        return wiretransferapi.postWithFiles(options);
    },
    UpdateRequest(id, data, token) {
        options.data = data;
        options.token = token;
        options.id = id;
        options.endpoint = `${options.baseEndpoint}`;
        return api.put(options);
    },
    UpdateRequestWithFiles(id, data, token) {
        options.data = data;
        options.token = token;
        options.id = id;
        options.endpoint = `${options.baseEndpoint}`;
        return wiretransferapi.putWithFiles(options);
    },
    GetFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetFormData/?id=${id}`;
        return api.get(options);
    },
    GetDraftFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetDraftRequest/?id=${id}`;
        return api.get(options);
    },
    GetAuditFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/audit/?id=${id}`;
        return api.get(options);
    },
    GetWorkflowStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/workflowstates/?id=${id}`;
        return api.get(options);
    },
    GetWorkflowHistoryStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/WorkflowHistoryStates/?id=${id}`;
        return api.get(options);
    },
    GetRequestWithTaskDetails(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RequestWithActiveTaskDetails/?id=${id}`;
        return api.get(options);
    },
    RejectRequest(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${level}`;
        return api.post(options);
    },
    ApproveRequestLevel1(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel1`;
        return api.post(options);
    },
    ApproveRequestLevel2(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel2`;
        return api.post(options);
    },
    AccountingApprove(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/AccountingApprove`;
        return api.post(options);
    },
    CFOApprove(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/CFOApprove`;
        return api.post(options);
    },
    CompleteVendorRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/Complete`;
        return api.post(options);
    },
    RequestForApproval(data, token, name) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${name}`;
        return api.post(options);
    },
    TriggerTask(data, token, action) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${action}`;
        return api.post(options);
    },
    GetRequests(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/List`;
        return api.list(options);
    },
    RenderUploadedFiles(token, id, category) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RenderUploadedFiles/?id=${id}&category=${category}`;
        return api.get(options);
    },
    GetUsers(token, agencyName, name) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${name}/?agency=${agencyName}`;
        return api.get(options);
    },
    SaveAsDraft(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/SaveRequestAsDraft`;
        return wiretransferapi.postWithFiles(options);
    },
    DownloadUploadTemplate(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadExternalTemplate`;
        return api.download(options);
    },
    PendingVendorApproveorComplete(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/PendingVendorResponseorComplete`;
        return api.post(options);
    },
    DownloadList(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadList`;
        return api.download(options);
    },
    DownloadMediaVendorACH(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadMediaVendorACH`;
        return api.download(options);
    },
};